import React from 'react'
import './css/dashboard.css';
import './css/icon.css';
import { UserContext } from '../UserProvider';
// import TutoringDetails from './TutoringDetails';
import RequestLesson from './RequestLesson';
import Loading from '../loading';
import Footer from './components/footer';
import Header from './components/Header';
import Navbar from './components/Navbar';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import Profile from './Profile';
import Proposal from './Proposal';
import ProposalDetails from './ProposalDetails';
import Checkout from './Checkout';
import CurrentSession from './CurrentSession';
import Wallet from './wallet';
// import Message from './Message';
// import Whiteboard from './Whiteboard';


const Dashboard = () => {
    const param = useParams();
    let page = param.page;
    const { user, logout, BaseUrl } = React.useContext(UserContext);
    const [subjects, setSubjects] = React.useState([]);
    const [busy, setBusy] = React.useState(true);

    const FETCH_SUBJECTS = async () => {

        try {
            const { data, status } = await axios.get(`${BaseUrl}/student/subjects`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data.success && status === 200) {
                setSubjects(data.data);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error.response.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }

    React.useEffect(() => {
        FETCH_SUBJECTS();
    }, []);

    let view;

    if (!page) {
        return <Navigate to="RequestLesson" replace={true} />
    }

    if (busy) {
        return <div className='vh-100'><Loading display="Loading" /></div>;
    }

    const Gate = new Map([
        ['subjects', false]
    ]);

    if (subjects.length > 0) {
        Gate.delete('subjects');
    }

    const lock = Gate.size > 0;

    if (lock && page !== 'Profile') {
        view = <Navigate to="/Student/Profile" replace={true} />
    }

    if (page === 'Profile') {
        view = <Profile subjects={subjects} setSubjects={setSubjects} gate={Gate} />;
    }

    if (subjects.length > 0) {

        if (page === 'RequestLesson') {
            view = <RequestLesson subjects={subjects} />
        }

        if (page === 'Proposals' && !param?.data) {
            view = <Proposal />
        }

        if (page === 'Proposals' && param.data) {
            view = <ProposalDetails id={param.data} />
        }

        if (page === 'Checkout' && param.data) {
            view = <Checkout />
        }

        if (page === 'Sessions' && !param.data) {
            view = <CurrentSession />
        }

        if (page === 'Wallet') {
            view = <Wallet />
        }

        // if (page === 'Message') {
        //     return <Message id={param?.data} />
        // }

        // if (page === 'Whiteboard' && param.data) {
        //     view = <Whiteboard id={param.data} />
        // }
    }

    return (
        <main className='dashboard d-flex flex-column position-absolute w-100 h-100'>
            <Header user={user} logout={logout} lock={lock} />
            <Navbar page={page} lock={lock} />
            {view}
            <Footer page={page} lock={lock} />
            {/* <div className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                <div className="toast-header">
                    <img src="..." className="rounded me-2" alt="..." />
                    <strong className="me-auto">Bootstrap</strong>
                    <small>11 mins ago</small>
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="toast"
                        aria-label="Close"
                    />
                </div>
                <div className="toast-body">Hello, world! This is a toast message.</div>
            </div> */}
        </main>
    )
}

export default Dashboard
