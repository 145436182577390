import React from 'react'
// import Avatar from './avatar.png'
import './css/proposal.css';
import { UserContext } from '../UserProvider';
import axios from 'axios';
import { Link } from 'react-router-dom';

const JobCard = ({ proposals, setProposals }) => {

    const { user, logout, BaseUrl } = React.useContext(UserContext);

    const handleReject = async (e, index, id) => {

        const element = e.currentTarget;
        element.disabled = true;

        try {
            const { data, status } = await axios.delete(`${BaseUrl}/student/proposals/reject/${id}`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setProposals(prev => prev.filter((_, i) => i !== index));
                element.disabled = false;
                element.innerHTML = 'REJECT';
                return;
            }

            element.innerHTML = 'Try again';

            if (status === 401) {
                logout();
            }

        } catch (error) {
            element.innerHTML = 'Try again';
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        element.disabled = false;
    }

    function printStringWithLength(str, length) {
        if (str.length >= length) {
            return str.slice(0, length) + ' ...';
        }
        return str.padEnd(length, ' ');
    }

    function getTimeAndDate(timestamp) {
        const dateObj = new Date(timestamp);
        // const hours = String(dateObj.getHours()).padStart(2, '0');
        // const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const monthIndex = String(dateObj.getMonth());
        const day = String(dateObj.getDay() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();

        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const month = months[monthIndex];

        // const time = `${hours}:${minutes}`;
        const date = `${day}-${month}-${year}`;

        return `${date}`;
    }

    return (
        proposals.map((proposal, index) => (

            <div className='card-list d-flex flex-wrap justify-content-between border-bottom py-4' key={index}>
                <div className='user-info-box d-flex gap-3'>
                    <img src={proposal?.user?.profile} height="50px" width="50px" className='rounded-circle' alt='profile' />
                    <div className='user-info'>
                        <h6 className='fw-bold'>{proposal?.user?.first_name + ' ' + proposal?.user?.last_name}</h6>
                        <h6>{getTimeAndDate(proposal?.created_at)}</h6>
                    </div>
                </div>

                <div className='msg-info'>
                    <h6 className='fw-bold'>Message</h6>
                    <h6>{printStringWithLength(proposal?.message, 20)}</h6>
                </div>

                <div className='subject-info'>
                    <h6 className='fw-bold'>{printStringWithLength(proposal?.subject.name, 20)}</h6>
                    <h6>{printStringWithLength(proposal?.job?.title, 20)}</h6>
                </div>

                <div className='minute-info'>
                    <h6 className='fw-bold'>Minutes</h6>
                    <h6>{proposal?.job?.minutes}</h6>
                </div>

                <div className='action-btn d-flex align-items-center justify-content-end gap-2'>
                    <Link type='button' to={`/Student/Proposals/${proposal?.id}`} className='btn btn-primary'>DETAILS</Link>
                    <button className='btn btn-primary' onClick={(e) => handleReject(e, index, proposal?.id)}>REJECT</button>
                </div>

            </div>
        ))
    )
}

const Proposal = () => {

    const [busy, setBusy] = React.useState(true);
    const [proposals, setProposals] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);

    React.useEffect(() => {
        FETCH_PROPOSALS()
    }, []);


    const FETCH_PROPOSALS = async () => {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/student/proposals/fetch/all`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setProposals(data.data);
                // console.log(data.data)
            }

            if (status === 401) {
                logout();
            }


        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }

    return (
        <section className='Proposal-student card-list-box d-flex justify-content-center align-items-center py-5 px-3 px-lg-0'>
            <div className='card container-lg pb-3'>
                <div className='card-header bg-white py-3 py-lg-4 px-4'>
                    <h6 className='card-title mb-0 fw-medium'>Proposals</h6>

                </div>
                <div className='card-body py-0'>
                    {busy ?
                        <div className='p-5 text-center'>
                            <div className="spinner-border" role="status" style={{ color: '#074276' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <>
                            {proposals.length === 0 ?
                                <p className='fs-5 my-2 p-3 text-center'>No Proposals Found</p>
                                :
                                <JobCard proposals={proposals} setProposals={setProposals} />
                            }
                        </>
                    }
                </div>
            </div>
        </section>
    )
}

export default Proposal