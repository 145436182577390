import React from 'react';
import axios from 'axios';
import './css/wallet.css';
import { UserContext } from '../UserProvider';


const Details = ({ data }) => {

    function getTimeAndDate(timestamp) {
        const dateObj = new Date(timestamp);
        // const hours = String(dateObj.getHours()).padStart(2, '0');
        // const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const monthIndex = String(dateObj.getMonth());
        const day = String(dateObj.getDay() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();

        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const month = months[monthIndex];

        // const time = `${hours}:${minutes}`;
        const date = `${day}-${month}-${year}`;

        return `${date}`;
    }

    return (
        data.map((details, index) => (
            <tr key={index}>
                <td>{index + 1}</td>
                <td>{details.session}</td>
                <td>R {details.credit === '0.00' ? details.debit : details.credit}</td>
                <td>{details.credit === '0.00' ? 'debit' : 'credit'}</td>
                <td>{getTimeAndDate(details.created_at)}</td>
            </tr>
        ))
    );
}

const Wallet = () => {

    const [amount, setAmount] = React.useState('');
    const [data, setData] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);
    const walletRef = React.useRef();

    const handleChange = (e) => {
        let { value } = e.target;
        value = value.replace(/\D/g, '');
        setAmount(value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'e') {
            event.preventDefault();
        }
    };

    const handlePayfast = async () => {

        try {
            const { data, status } = await axios.post(`${BaseUrl}/student/wallet/add/payment`, { amount: amount }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                const testingMode = false;
                const pfHost = testingMode ? 'https://sandbox.payfast.co.za/eng/process' : 'https://www.payfast.co.za/eng/process';

                const form = document.createElement('form');
                form.method = 'POST';
                form.action = pfHost;
                // form.target = '_blank';

                const dataArr = Object.entries(data.data);

                for (let index = 0; index < dataArr.length; index++) {
                    const input = dataArr[index];
                    const inputData = document.createElement('input');
                    inputData.type = 'hidden';
                    inputData.name = input[0];
                    inputData.value = input[1];
                    form.appendChild(inputData);
                }

                walletRef.current.appendChild(form);
                form.submit();
                form.remove();
            }

            if (status === 401) {
                logout();
            }


        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }
    }

    async function fetch_acc_details() {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/student/wallet/details`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setData(data.data);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }
    }

    React.useEffect(() => {
        fetch_acc_details();
    }, []);

    return (
        <section className='wallet py-5' ref={walletRef}>
            <div className='card container-lg pb-3'>
                <div className='card-header bg-white py-3 py-lg-4 px-4'>
                    <div className=' d-flex align-items-center justify-content-between'>
                        <h6 className='card-title mb-0 fw-medium'>Wallet</h6>
                        <div className='fs-6'>
                            Available Amount
                            <div className='text-end fw-medium'>
                                R {data?.amount ?? 'loading...'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className='input-group'>
                        <input className='form-control' onKeyPress={handleKeyPress} value={amount} onChange={handleChange} type='number' min={5} />
                        <button className='btn btn-primary' onClick={handlePayfast}>Add amount</button>
                    </div>

                    {data?.history?.length > 0 &&
                        <div className="table-responsive px-1 pt-4">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>S.NO</th>
                                        <th>SESSION ID</th>
                                        <th>AMOUNT</th>
                                        <th>TYPE</th>
                                        <th>DATE</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <Details data={data?.history} />
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </section>
    )
}

export default Wallet