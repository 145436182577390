import React from 'react'
import './css/bank.css'
import axios from 'axios';
import { UserContext } from '../UserProvider';
import { Link } from 'react-router-dom';

const Bank = () => {

    const [banks, setbanks] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);
    const [busy, setBusy] = React.useState(true);


    const FETCH_USER_BANKS = async () => {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/tutor/bank/detail`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data.success && status === 200) {
                setbanks(data.data);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error.response.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }

    React.useEffect(() => {
        FETCH_USER_BANKS();
    }, []);


    function getTimeAndDate(timestamp) {
        const dateObj = new Date(timestamp);
        // const hours = String(dateObj.getHours()).padStart(2, '0');
        // const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const monthIndex = String(dateObj.getMonth());
        const day = String(dateObj.getDay() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();

        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const month = months[monthIndex];

        // const time = `${hours}:${minutes}`;
        const date = `${day}-${month}-${year}`;

        return `${date}`;
    }

    return (
        <section className='bank-account my-5'>
            <div className='container-lg'>
                <div className='card'>
                    <div className='card-header bg-white py-3 py-lg-4 px-4'>
                        <div className='d-flex align-items-center flex-wrap justify-content-between'>
                            <h6 className='card-title mb-0 fw-medium'>Bank accounts</h6>
                            <Link to="/Tutor/Banks/Add" type="button" className='btn btn-primary mt-2 m-sm-0'>Add account</Link>
                        </div>
                    </div>
                    <div className='card-body'>
                        <div className="table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th>S.NO</th>
                                        <th>Bank Name</th>
                                        <th>Account Number</th>
                                        <th>Account Type</th>
                                        <th>Name Of Account</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {busy ?
                                        <tr>
                                            <td colSpan={6}>
                                                <div className='pt-5 text-center'>
                                                    <div className="spinner-border" role="status" style={{ color: '#074276' }}>
                                                        <span className="visually-hidden">Loading...</span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        :
                                        <>
                                            {banks?.length === 0 &&
                                                <tr><td colSpan={6}><h5 className='mb-0 mt-4 text-center'>No accounts found</h5></td></tr>
                                            }
                                            {
                                                banks.map((bank, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{bank.bank_name}</td>
                                                        <td>{bank.account_no}</td>
                                                        <td>{bank.account_type}</td>
                                                        <td>{bank.account_name}</td>
                                                        <td>{getTimeAndDate(bank.created_at)}</td>
                                                    </tr>
                                                ))
                                            }
                                        </>
                                    }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Bank
