import React from 'react'
import './css/dashboard.css';
import './css/icon.css';
import { UserContext } from '../UserProvider';
import AvailableTutoring from './AvailableTutoring';
import TutoringDetails from './TutoringDetails';
import Bank from './Bank';
import Proposal from "./Proposal"
import Loading from '../loading';
import Footer from './components/footer';
import Header from './components/Header';
import Navbar from './components/Navbar';
import axios from 'axios';
import { Navigate, useParams } from 'react-router-dom';
import Profile from './Profile';
import AddBank from './AddBank';
import Earning from "./Earning";
import ProposalDetails from './ProposalDetails';
import Cashout from './Cashout';


const Dashboard = () => {
    const param = useParams();
    let page = param.page;
    const { user, logout, BaseUrl, AppUrl } = React.useContext(UserContext);
    const [subjects, setSubjects] = React.useState([]);
    const [busy, setBusy] = React.useState(true);

    const FETCH_SUBJECTS = async () => {

        try {
            const { data, status } = await axios.get(`${BaseUrl}/tutor/subjects`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data.success && status === 200) {
                setSubjects(data.data);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error.response.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }

    React.useEffect(() => {
        FETCH_SUBJECTS();
    }, []);

    let view;

    if (!page) {
        return <Navigate to="Tutoring" replace={true} />
    }

    if (busy) {
        return <div className='vh-100'><Loading display="Loading" /></div>;
    }

    const Gate = new Map([
        ['subjects', false],
        ['is_approve', false]
    ]);

    if (subjects.length > 0) {
        Gate.delete('subjects');
        for (let index = 0; index < subjects.length; index++) {
            const subject = subjects[index];
            if (subject.is_approve === 'true') {
                Gate.delete('is_approve');
            }
        }
    }

    const lock = Gate.size > 0;

    if (lock && page !== 'Profile') {
        view = <Navigate to="/Tutor/Profile" replace={true} />
    }

    if (page === 'Profile') {
        view = <Profile subjects={subjects} setSubjects={setSubjects} gate={Gate} />;
    }

    if (subjects.length > 0) {

        if (page === 'Tutoring' && !param?.data) {
            view = <AvailableTutoring />
        }

        if (page === 'Tutoring' && param.data) {
            view = <TutoringDetails job_id={param.data} />
        }

        if (page === 'Proposals' && !param?.data) {
            view = <Proposal />
        }

        if (page === 'Proposals' && param.data) {
            view = <ProposalDetails id={param.data} />
        }

        if (page === 'Banks') {
            view = <Bank />
        }

        if (page === 'Earnings') {
            view = <Earning />
        }

        if (page === 'Earnings' && param.data) {
            view = <Cashout />
        }

        if (page === 'Banks' && param.data === 'Add') {
            view = <AddBank />
        }
    }

    return (
        <main className='dashboard position-absolute w-100 h-100'>
            <Header user={user} logout={logout} lock={lock} AppUrl={AppUrl} />
            <Navbar page={page} lock={lock} />
            {view}
            <Footer page={page} lock={lock} />
        </main>
    )
}

export default Dashboard
