import React from 'react'
// import Avatar from './avatar.png'
import './css/avalaibletutoring.css';
import axios from 'axios';
import { UserContext } from '../UserProvider';
import { Link } from 'react-router-dom';

export const JobCard = ({ jobs, setJobs }) => {

    // const navigate = useNavigate();
    const { user, logout, BaseUrl } = React.useContext(UserContext);


    function printStringWithLength(str, length) {
        if (str.length >= length) {
            return str.slice(0, length) + ' ...';
        }
        return str.padEnd(length, ' ');
    }

    function getTimeAndDate(timestamp) {
        const dateObj = new Date(timestamp);
        // const hours = String(dateObj.getHours()).padStart(2, '0');
        // const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const monthIndex = String(dateObj.getMonth());
        const day = String(dateObj.getDay() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();

        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const month = months[monthIndex];

        // const time = `${hours}:${minutes}`;
        const date = `${day}-${month}-${year}`;

        return `${date}`;
    }

    const handleIgnore = async (e, index, id) => {

        const button = e.currentTarget;

        try {
            button.disabled = true;
            const { data, status } = await axios.post(`${BaseUrl}/tutor/job/ignore`, { id: id }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                button.disabled = false;
                setJobs(prev => prev.filter((_, i) => i !== index));
                return;
            }

            button.innerHTML = 'Try again';

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error.response.status === 401) {
                logout();
            }
            button.innerHTML = 'Try again';
        }

        button.disabled = false;
    }

    return (
        jobs.map((job, index) => (

            <div className='card-list d-flex flex-wrap justify-content-between border-bottom py-4' key={index}>
                <div className='user-info-box d-flex gap-3'>
                    <img src={job?.user?.profile} height="50px" width="50px" className='rounded-circle' alt='profile' />
                    <div className='user-info'>
                        <h6 className='fw-bold'>{job?.user.first_name + ' ' + job?.user.last_name}</h6>
                        <h6>{getTimeAndDate(job?.created_at)}</h6>
                    </div>
                </div>

                <div className='subject-info'>
                    <h6 className='fw-bold'>{printStringWithLength(job?.subject.name)}</h6>
                    <h6>{printStringWithLength(job?.title, 13)}</h6>
                </div>

                <div className='price-info'>
                    <h6 className='fw-bold'>Price</h6>
                    <h6>R {job?.price}</h6>
                </div>

                <div className='action-btn d-flex align-items-center justify-content-end gap-2'>
                    <Link className='btn btn-primary' type='button' to={`/Tutor/Tutoring/${job.id}`}>DETAILS</Link>
                    <button className='btn btn-primary' onClick={(e) => handleIgnore(e, index, job.id)}>IGNORE</button>
                </div>

            </div>
        ))
    )
}

const AvailableTutoring = () => {

    const [busy, setBusy] = React.useState(true);
    const [jobs, setJobs] = React.useState([]);
    // const [error, setError] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);

    const FETCH_JOBS = async () => {

        try {
            const { data, status } = await axios.get(`${BaseUrl}/tutor/job/fetch/all`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setJobs(data.data);
            }

            if (!data?.success && status === 200) {
                // setError(data?.code);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error.response.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }

    React.useEffect(() => {
        FETCH_JOBS();
    }, []);

    // console.log(jobs)

    return (
        <div className='mt-5'>
            {/* {error.length > 0 &&
                <div className='container'>
                    <div className="alert alert-danger" role="alert">
                        {error === 'SUBJECTS_REQUIRED' && 'Please add subject on profile settings'}
                    </div>
                </div>
            } */}
            <section className='Available-tutoring card-list-box d-flex justify-content-center align-items-center px-3 px-lg-0'>
                <div className='card container-lg'>
                    <div className='card-header bg-white py-3 py-lg-4 px-4'>
                        <h6 className='card-title mb-0 fw-medium'>Available Tutoring</h6>
                    </div>
                    {busy ?
                        <div className='p-5 text-center'>
                            <div className="spinner-border" role="status" style={{ color: '#074276' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <div className='card-body py-0'>
                            {jobs.length === 0 ?
                                <p className='fs-5 p-3 text-center'>No Tutoring Available</p>
                                :
                                <JobCard jobs={jobs} setJobs={setJobs} />
                            }
                        </div>
                    }
                </div>
            </section>
        </div>
    )
}

export default AvailableTutoring