import React from 'react'
import './NavBar.css'
import Tutor from '../../Images/tutor.png'
import cross from '../../Images/cros.png'
import bar from '../../Images/barr.png'
import { Link } from "react-router-dom";
import { UserContext } from '../../UserProvider'
// import { useState } from 'react'
const NavBar = () => {
  // const [sa, setsa] = useState('')
  const { user, logout, BaseUrl, AppUrl } = React.useContext(UserContext);



  return (

    <div>
      <header className='web'>

        <div className="container-fluid ">


          <div className="navb-logo pt-4 ">
            <img src={Tutor} alt="Logo" />
          </div>



          <div className='centr'>

            <div className="item" >
              <Link to="/" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>Home</Link>
            </div>

            <div className="item" >
              <Link to="/AboutUs" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>About</Link>
            </div>

            <div className="item" >
              <Link to="/Contact" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>Contact</Link>
            </div>
          </div>
          <div className='second-div'>
            {!('token' in user) &&
              <>
                <div className="item mx-2"  >
                  <Link to="/Login" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>
                    <div style={{ display: 'flex' }} ><i className="fa fa-user pt-1 mx-1" style={{ fontSize: '20px' }}></i>
                      <span style={{ fontFamily: 'Poppins' }}>Login</span>
                    </div>
                  </Link>
                </div>

                <div className="item-button mx-2" >
                  <Link to="/Register" className='bg-transparent text-decoration-none'>
                    <button className='but px-4 py-2' type="button" style={{ fontFamily: 'Poppins' }}>Register</button>
                  </Link>
                </div>
              </>
            }

            {('token' in user) &&
              <>
                {/* <div className="item mx-2"  >
                  <Link to="/Login" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none' }}>
                    <div style={{ display: 'flex' }} ><i className="fa fa-user pt-1 mx-1" style={{ fontSize: '20px' }}></i>
                      <span style={{ fontFamily: 'Poppins' }}>Dashboard</span>
                    </div>
                  </Link>
                </div> */}

                <div className="item-button mx-2" >
                    <button className='but px-4 py-2' type="button" onClick={logout} style={{ fontFamily: 'Poppins' }}>Logout</button>
                </div>
              </>
            }
          </div>



          <div className="canva" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
            <img src={bar} alt='' style={{ width: '40px', height: '40px' }}></img>
          </div>

          <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-header">

              <button type="button" className='ms-auto' data-bs-dismiss="offcanvas" aria-label="Close" style={{ backgroundColor: 'transparent', border: 'none' }}> <img src={cross} alt='' style={{ width: '30px', height: '30px', position: 'relative' }}></img></button>
            </div>
            <div className="offcanvas-body">
              <div className='text-center' >
                <div className="line pt-4">
                  <Link to="/" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none', fontSize: '30px' }}>Home</Link></div>
                <div className="line pt-5">
                  <Link to="/AboutUs" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none', fontSize: '30px' }} >About</Link>
                </div>

                <div className="line pt-5">
                  <Link to="/Contact" style={{ fontFamily: 'Poppins', color: 'white', textDecoration: 'none', fontSize: '30px' }}  >Contact</Link>
                </div>

                <div className="line pt-5">
                  <div style={{ color: 'white', textDecoration: 'none', fontSize: '30px' }}><i className="fa fa-user px-1"></i><span style={{ fontFamily: 'Poppins' }}>Login</span></div>
                </div>

                <div className="line pt-5">
                  <button className='but px-4 py-2' type="button" style={{ fontFamily: 'Poppins' }}>Register</button>
                </div>

              </div>

            </div>
          </div>
        </div>
      </header>
    </div>
  )
}

export default NavBar