import React from 'react'
import './Contact.css'
import NavBar from '../NavBar/NavBar'
import ContactForm from '../ContactForm/ContactForm'
import Footer from '../Footer/Footer'

const Contact = () => {
  return (
    <div>
    <div className='contact'>
<NavBar/>
<div className='About-one'>
  Contact
</div>

    </div>

<ContactForm/>
<Footer/>
    </div>
  )
}

export default Contact