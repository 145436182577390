import React from 'react'
import './Tutors.css'
import Book from '../../Images/books.png'
import BookOne from '../../Images/bookOne.png'

const Tutors = () => {
  return (
    <div>
<div className='fluid-institute' style={{paddingBottom:'50px'}}>
<div className='text-center pt-5'>
  <h1 style={{fontFamily:'Poppins'}}>Tutors Community</h1>
</div>
<div className='row mt-5 roww'>
<div className='col-12 col-md-6' >
<p className='par'>
Tutors Community is a platform where tutor’s make life easy for any student enrolled in a module where
 the tutor did exceptionally well .The idea is to elevate students confidence and marks in any module
  he/she enrolled to.Tutors Community was established in 2021 with an idea of make life easy for any student
   which might be struggling to understand anything related to his/her studies.
Tutors Community welcomes you in a platform and in the community of teachers.  
</p>

</div>
<div className='col-12 col-md-6' >
  <div className='des' >
    <img src={Book} alt='' className='book-one'></img>
  </div>
</div>

</div>


{/*  */}
<div className='row mt-5 rowss'>
<div className='col-12 col-md-6' >
<div className='desk' >
    <img src={BookOne} alt='' className='book-one'></img>
  </div>


</div>
<div className='col-12 col-md-6' >
<p className='par'>
Tutors Community is a platform where tutor’s make life easy for any student enrolled in a module where
 the tutor did exceptionally well .The idea is to elevate students confidence and marks in any module
  he/she enrolled to.Tutors Community was established in 2021 with an idea of make life easy for any student
   which might be struggling to understand anything related to his/her studies.
Tutors Community welcomes you in a platform and in the community of teachers.  
</p>
</div>

</div>
{/*  */}





    
</div>



    </div>
  )
}

export default Tutors