import React from "react";
import { UserContext } from "./UserProvider";
import TutorDashboard from "./tutor/Dashboard";
import StudentDashboard from "./student/Dashboard";
import Login from './Pages/Login'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from './Pages/Register';
import Home from './Pages/Home/Home';
import AboutUs from './Pages/AboutUs/AboutUs'
import Contact from './Pages/Contact/Contact';

const RoutesHandler = () => {

    const { user } = React.useContext(UserContext);

    let routes = [];


    // routes.push(<Route key="dashboard" path="Tutor/:page/:data" element={<TutorDashboard />} />);

    if (!('token' in user)) {
        // routes.push(<Route key="login" path="*" element={<Login />} />);
        routes.push(<Route key="register" path="/Register" element={<Register />} />);
        routes.push(<Route key="login" path="/Login" element={<Login />} />);

        routes.push(<Route key="dashboard" path="Tutor" >
            <Route index element={<Login />} />
            <Route path=":page" element={<Login />} />
            <Route path=":page/:data" element={<Login />} />
        </Route>);

        routes.push(<Route key="dashboard" path="Student" >
            <Route index element={<Login />} />
            <Route path=":page" element={<Login />} />
            <Route path=":page/:data" element={<Login />} />
        </Route>);

    }

    else {

        routes.push(<Route key="dashboard" path="Tutor" >
            <Route index element={<TutorDashboard />} />
            <Route path=":page" element={<TutorDashboard />} />
            <Route path=":page/:data" element={<TutorDashboard />} />
        </Route>);

        routes.push(<Route key="dashboard" path="Student" >
            <Route index element={<StudentDashboard />} />
            <Route path=":page" element={<StudentDashboard />} />
            <Route path=":page/:data" element={<StudentDashboard />} />
        </Route>);

        // routes.push(<Route key="404" path="*" element={<NotFound />} />);
    }

    return (
        <BrowserRouter>
            <Routes>
                {routes}
                <Route path="/" element={<Home/>}></Route>
          <Route path="/AboutUs" element={<AboutUs/>}></Route>
          <Route path="/Contact" element={<Contact/>}></Route>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesHandler
