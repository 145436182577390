import React from "react";
import "./Home.css";
import pencil from "../../Images/pencil.png";
import pen from "../../Images/pen.png";
import book from "../../Images/book.png";
import cup from "../../Images/cup.png";
import monitor from "../../Images/monitor.png";
import NavBar from "../NavBar/NavBar";
import About from "../About/About";
import Institute from "../Institute/Institute";
import Community from "../Community/Community";
import Testimonals from "../Testimonal/Testimonals";
import Footer from "../Footer/Footer";

const Home = () => {
  return (
    <div >
      <section className="home">
     
        <div className="cen">
          <img src={monitor} alt="monitor" style={{ height: "200px" }}></img>
        </div>
        <NavBar/>

        
             <div className="con-cen">
             <div className="head-one text-center " style={{fontFamily:'Poppins'}}>
             Need Tutoring Assitance Remotely?
             </div>
             <div className="head-two text-center pt-3" style={{fontFamily:'Poppins'}}>
             Get Tutoring assistance remotely at your convenient time
             </div>
             <div className="head-three mt-3">
            <button className="text-center learn px-5 py-2" style={{fontFamily:'Poppins'}}>Learn more</button>
             </div>
             </div>
            

        <div className="pencil">
          <img src={pencil} alt="pencil" style={{ height: "500px" }} />
        </div>

        <div className="pen">
          <img
            src={pen}
            alt="pen"
            style={{ height: "300px", width: "300px" }}
          ></img>
        </div>
        <div className="book">
          <img src={book} alt="book" className="boo" />
        </div>
        <div className="cup">
          <img src={cup} alt="cup" style={{ height: "300px" }} />
        </div>
      </section>
      <About/>
      <Institute/>
      <Community/>
      <Testimonals/>
      <Footer/>
    </div>
  );
};

export default Home;