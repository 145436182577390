import React from 'react'
import './ContactForm.css'

const ContactForm = () => {
  return (
    <div className='contact-for'>

{/*  */}
<div className='row pt-5 contact-rows'>
    <div className='col-5  col-five' >
   
    <h1 className='conta mt-4'>
We always ready <br/>to hear from you 
</h1>
   



    </div>
    <div className='col-7 px-5 pb-4 col-seven' >
    <div className='contact-row pt-4'>
<input type='text' style={{width:'100%'}} className='mx-3 inp-color py-3 nam' placeholder='Your Name'></input>
<input type='text' style={{width:'100%'}} className='mx-3 inp-color py-3' placeholder='Email'></input>
    </div>
    <div className='text-area pt-5'>
        <textarea style={{width:'100%',height:'30vh'}} className='mx-3 text-inp' placeholder='Write Message'></textarea>
    </div>
    <div className='text-center pt-5 pb-5'>
    <button className='Submit-btn  py-2'>Submit</button>
    </div>
  




    </div>
</div>
{/*  */}




    </div>
  )
}

export default ContactForm