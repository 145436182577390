import React from 'react'
import userIcon from '../asset/user-icon.png'
import backIcon from '../asset/bank-icon.png'
import logoutIcon from '../asset/logout-icon.png'
import './header.css';
import { memo } from 'react';
import { Link } from 'react-router-dom';

const Header = ({ user, logout, lock, AppUrl }) => {

    return (
        <header className='bg-white'>
            <div className='container-xl d-flex justify-content-between align-items-center px-xl-0 px-3 py-2'>
                <div className='logo'>
                    <img src='https://tutorscom.com/assets/images/resources/tutor-cummunity2.jpg' height="95px" width="auto" alt='logo' />
                </div>
                <nav className='d-flex align-items-center gap-2'>
                    <div className='noti'>
                        <Link type='button' to={`${AppUrl}/tutor/message/?token=${user.token}`} target="_blank" className='btn rounded-circle p-3 text-muted border-0'>
                            <span className="material-icons fw-lighter">
                                message
                            </span>
                        </Link>
                    </div>
                    {/* <div className="dropdown position-static" >
                        <div type="button" data-bs-toggle="dropdown" aria-expanded="true" >
                            <div className='profile d-flex align-items-center btn border-0 p-2 gap-3'>
                                <div className="teachImageDiv"><img src={user?.profile} className="rounded-5" height="50px" width="50px" alt="profile-img" /></div>
                                <div className='d-flex align-items-center'>
                                    <h5 className='mb-0'>{user?.first_name}</h5>
                                </div>
                                <span className="p-2 material-icons fw-bolder">
                                    arrow_drop_down
                                </span>
                            </div>
                        </div>
                        <ul className="dropdown-menu" style={{
                            background: 'rgb(245 245 245)',
                            border: '1px solid rgb(231 231 231)'
                        }} >
                            <li>
                                <Link to='/Tutor/Profile' className="dropdown-item py-2 my-1 d-flex align-items-center gap-2">
                                    <img src={userIcon} alt="icon" height="22px" width="22px" />
                                    Profile Setting
                                </Link>
                            </li>
                            {!lock &&
                                <li>
                                    <Link to='/Tutor/Banks' className="dropdown-item py-2 my-1 d-flex align-items-center gap-2">
                                        <img src={backIcon} alt="icon" height="20px" width="20px" />
                                        Bank Account
                                    </Link>
                                </li>
                            }
                            <li>
                                <button className="dropdown-item py-2 my-1 d-flex align-items-center gap-2" onClick={logout}>
                                    <img src={logoutIcon} alt="icon" height="20px" width="20px" />
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div> */}


                    <div className="dropdown">
                        <div
                            type="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <div className='profile d-flex align-items-center btn border-0 p-2 gap-3'>
                                <div className="teachImageDiv"><img src={user?.profile} className="rounded-5" height="50px" width="50px" alt="profile-img" /></div>
                                <div className='d-flex align-items-center'>
                                    <h5 className='mb-0'>{user?.first_name}</h5>
                                </div>
                                <span className="p-2 material-icons fw-bolder">
                                    arrow_drop_down
                                </span>
                            </div>
                        </div>
                        <ul className="dropdown-menu" style={{
                            background: 'rgb(245 245 245)',
                            border: '1px solid rgb(231 231 231)'
                        }} >
                            <li>
                                <Link to='/Tutor/Profile' className="dropdown-item py-2 my-1 d-flex align-items-center gap-2">
                                    <img src={userIcon} alt="icon" height="22px" width="22px" />
                                    Profile Setting
                                </Link>
                            </li>
                            {!lock &&
                                <li>
                                    <Link to='/Tutor/Banks' className="dropdown-item py-2 my-1 d-flex align-items-center gap-2">
                                        <img src={backIcon} alt="icon" height="20px" width="20px" />
                                        Bank Account
                                    </Link>
                                </li>
                            }
                            <li>
                                <button className="dropdown-item py-2 my-1 d-flex align-items-center gap-2" onClick={logout}>
                                    <img src={logoutIcon} alt="icon" height="20px" width="20px" />
                                    Logout
                                </button>
                            </li>
                        </ul>
                    </div>


                </nav>
            </div>
        </header>
    )
}

export default memo(Header)
