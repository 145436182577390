import React from 'react'
import './Footer.css'
import Foote from '../../Images/Efooter.png'
import Tutor from '../../Images/tutor.png'
import facebook from '../../Images/facebook.png'
import instagram from '../../Images/instagram.png'
import twitter from '../../Images/twitter.png'
import youtube from '../../Images/youtube.png'

const Footer = () => {
    let curYear = new Date();
    curYear = curYear.getFullYear();
  return (
    <div className='footer-main'>
<div className='footer-img' ><img src={Foote} alt=''  /></div>
<div className='footer-body'>
<div className='row row-cols-1 row-cols-md-2 row-cols-xl-4 row-col-lg-3 g-5 py-5'>
    {/* col-one */}
    <div className='col'>
        <div  >
       
       <img src={Tutor} alt='' className='footer-logo'/>
       
        
       
       
        <p style={{color:'white'}}>Tutor’s Community is a platform where<br/> tutor’s make life easy for any
         student <br/> enrolled in a module where the tutor<br/> did exceptionally well .</p>

      <div className='footer-row'>
      <img src={facebook} alt="" />
              <img className="icon mx-2" src={instagram} alt="" />
              <img className="icon" src={twitter} alt="" />
              <img className="icon mx-2" src={youtube} alt="" />
        
      </div>   
        </div>
    </div>
     {/* col-one */}
       {/* col-one */}
    <div className='col '>
<div className='Links'>
    <h2 style={{fontSize:'20px'}}>Links</h2>
    <div className='link-home'>
        <h6 className='hom pb-2'>Home</h6>
        <h6 className='hom pb-2'>About us</h6>
        <h6 className='hom pb-2'>University</h6>
        <h6 className='hom pb-2'>Contact</h6>
    </div>
</div>

    </div>
     {/* col-one */}
       {/* col-one */}
       <div className='col '>
<div className='footer-contact'>
    <h2 style={{fontSize:'20px'}}>Contact</h2>
    <div className='link-home'>
        <h6 className='hom pb-2'>Home</h6>
        <h6 className='hom pb-2'>About us</h6>
        <h6 className='hom pb-2'>University</h6>
        <h6 className='hom pb-2'>Contact</h6>
    </div>
</div>

    </div>
     {/* col-one */}
       {/* col-one */}
       <div className='col '>
<div className='footer-Subscribe'>
    <h2 style={{fontSize:'20px',lineHeight:'30px'}} className='Subscribe'>Subscribe our <br/> Newsletter</h2>
    <div className='link-home'>
        <h6 className='hom pb-2 guidance ' style={{paddingRight:'50px'}}>Offer guidance to visitors without
the need to scroll  back  to the back.
</h6>
<div  className='Enter pt-1'>
    <input type='text' placeholder='Enter your Email' className='py-2 ema' ></input>
    <button className='py-2 up'>Sign up</button>
</div>
        
    </div>
</div>

    </div>
     {/* col-one */}
     

</div>
<hr style={{color:'white'}}/>
<div className='text-center pb-4 pt-3' style={{color:'white'}}>© Copyright {curYear} by Tutors Community</div>
</div>

    </div>
  )
}

export default Footer