import React from 'react'
import background from './asset/login.png'
import user from './asset/user.png'
import axios from 'axios'
import lock from './asset/lock.png'
import './css/login.css'
import { UserContext } from '../UserProvider'
import { Link, useLocation, useNavigate } from 'react-router-dom'


const LOGIN_USER = async (payload,BaseUrl) => {
    try {
        const { data } = await axios.post(`${BaseUrl}/user/login`, payload);
        return await data

    } catch (error) {
        console.log(error)
        return await error?.response;
    }
}

const Login = () => {

    const url = useLocation();
    const navigate = useNavigate();
    const roleInUrl = url.pathname.includes('Tutor') ? 'tutor' : 'student';
    const [data, setData] = React.useState({ role: roleInUrl, email: '', password: '', remember: false });
    const [error, setError] = React.useState('');
    const [Onbusy, setOnbusy] = React.useState(false);
    const { setUser, BaseUrl } = React.useContext(UserContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'remember') {
            setData({ ...data, [name]: e.target.checked })
        } else {
            setData({ ...data, [name]: value })
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (Onbusy === true) {
            return false;
        }

        console.log(url)

        setOnbusy(true);

        const { role, email, password, remember } = data;

        const payload = {
            'email': email,
            'password': password,
            'role': role
        }

        const res = await LOGIN_USER(payload, BaseUrl)

        if (res?.success) {
            localStorage.setItem('user', JSON.stringify({ 'token': res.token }));
            if (url.pathname.includes('Tutor') || url.pathname.includes('Student')) {
                setUser({ 'token': res.token });
                return
            }
            navigate(role === 'student' ? '/Student' : 'Tutor');
            setUser({ 'token': res.token })
        }
        else if (res?.code === 'INVALID_USER') {
            setError('Invalid login credentials.');
        }
        else {
            setError('Something went wrong. Please try again.');
        }

        setOnbusy(false);
    }

    document.getElementsByTagName('body')[0].style.zoom = '100%'

    return (
        <main className='d-flex vh-100 login m-auto' style={{ maxWidth: '2000px' }}>
            <div className='d-none d-lg-block col h-100' style={{ background: `url(${background})`, backgroundSize: 'cover' }}>
                {/* <img src={background} height="100%" width="100%" alt='background' /> */}

                <div className='d-flex h-75 justify-content-center flex-column align-items-center'>
                    <h5 className='fs-5 fw-normal mb-3'>Looks like you are new here!</h5>
                    <h3>Join the largest Tutors</h3><h3>Community in the world</h3>
                </div>

            </div>
            <div className='col-12 col-lg-7 bg-white px-3 px-sm-5 position-relative'>
                <div className='mt-3 me-4 end-0 position-absolute'><Link to={'/Register'} className='btn border-0a'>Register</Link></div>
                <div className='d-flex flex-column justify-content-center h-100 gap-2'>
                    <div className='fs-6 d-flex align-items-center fw-medium mb-1'>
                        Login as
                        <div className='mx-2'>
                            <select onChange={handleChange} name="role" value={data.role} className="form-select fs-6 m-0 fw-medium border form-select-sm" >
                                <option value="tutor">tutor</option>
                                <option value="student">student</option>
                            </select>
                        </div>
                        on
                    </div>
                    <h4>Tutors Community</h4>
                    <form className='d-flex flex-column mt-4 gap-4 col-12 col-lg-10 col-xl-9' onSubmit={handleSubmit}>
                        <div className='border-bottom d-flex align-items-center pb-1'>
                            <label htmlFor='email'><img src={user} height="16px" width="auto" alt="email" /></label>
                            <input className='border-0 form-control' name='email' value={data.email} onChange={handleChange} placeholder='Email' type="email" id='email' required />
                        </div>
                        <div className='border-bottom d-flex align-items-center pb-1'>
                            <label htmlFor='password'><img src={lock} height="16px" alt="lock" /></label>
                            <input className='border-0 form-control border-0' value={data.password} onChange={handleChange} name='password' type="password" id='password' placeholder='Password' required />

                        </div>
                        <p className={`m-0 p-0 text-danger ${error.length > 0 ? 'd-block' : 'd-none'}`}>{error}</p>
                        <div className='d-flex flex-wrap align-items-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <input type="checkbox" className='form-check-input' id='remember' name='remember' value={data.remember} onChange={handleChange} /><label htmlFor='remember' className='text-muted ms-2 mt-1'>Remember me</label>
                            </div>
                            {/* <button className='btn text-black-50 border-0' type='button'>Forgot password ?</button> */}
                        </div>
                        <button className='btn btn-primary rounded-4' disabled={Onbusy} type='submit' style={{ width: '160px' }}>
                            {Onbusy ? 'Please wait' : 'Login'}
                        </button>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Login
