import React from 'react';
import axios from 'axios';
import './css/earning.css';
import { UserContext } from '../UserProvider';
import { Link } from 'react-router-dom';


const Details = ({ data }) => {

    function printStringWithLength(str, length) {
        if (str.length >= length) {
            return str.slice(0, length) + ' ...';
        }
        return str.padEnd(length, ' ');
    }

    // function getTimeAndDate(timestamp) {
    //     const dateObj = new Date(timestamp);
    //     // const hours = String(dateObj.getHours()).padStart(2, '0');
    //     // const minutes = String(dateObj.getMinutes()).padStart(2, '0');
    //     const monthIndex = String(dateObj.getMonth());
    //     const day = String(dateObj.getDay() + 1).padStart(2, '0');
    //     const year = dateObj.getFullYear();

    //     const months = [
    //         'January', 'February', 'March', 'April', 'May', 'June',
    //         'July', 'August', 'September', 'October', 'November', 'December'
    //     ];

    //     const month = months[monthIndex];

    //     // const time = `${hours}:${minutes}`;
    //     const date = `${day}-${month}-${year}`;

    //     return `${date}`;
    // }

    return (
        data.map((details, index) => (
            <tr key={index}>
                <td>
                    <div className='d-flex gap-3'>
                        <img src={details?.job?.user?.profile} height="50px" width="50px" className='rounded-circle' alt='profile' />
                        <div className='user-info'>
                            <h6 className='fw-bold'>{details?.job?.user?.first_name} {details?.job?.user?.last_name}</h6>
                            <h6>date here</h6>
                        </div>
                    </div>
                </td>
                <td>
                    <div className='subject-info'>
                        <h6 className='fw-bold'>{printStringWithLength(details?.job?.subject, 20)}</h6>
                        <h6>{printStringWithLength(details?.job?.title, 20)}</h6>
                    </div>
                </td>
                <td colSpan={2}>
                    <div className='price-info'>
                        <h6 className='fw-bold'>Price</h6>
                        <h6>R {details?.job?.price}</h6>
                    </div>
                </td>
                <td colSpan={2}>
                    <div className='status-info'>
                        <h6 className='fw-bold'>Status</h6>
                        <h6>{details?.job?.status}</h6>
                    </div>
                </td>
                <td>
                    <div className='text-center pt-2'>
                        {details?.job?.status === 'progress' &&
                            <button className='btn btn-primary' disabled>Cashout</button>
                        }
                        {details?.job?.status === 'completed' && details?.payment_status === 'incomplete' &&
                            // <button className='btn btn-primary'>Cashout</button>
                            <Link type='button' className='btn btn-primary' to={`/Tutor/Earnings/${details?.token}`} >
                                Cashout
                            </Link>
                        }
                        {details?.job?.status === 'completed' && details?.payment_status === 'requested' &&
                            <button className='btn btn-primary' disabled>Requested</button>

                        }
                        {details?.job?.status === 'completed' && details?.payment_status === 'completed' &&
                            <button className='btn btn-primary' disabled>Withdraw</button>
                        }
                    </div>
                </td>
            </tr>
        ))
    );
}

// const Modal = () => {

//     const [banks, setbanks] = React.useState([]);
//     const { user, logout, BaseUrl } = React.useContext(UserContext);
//     const [busy, setBusy] = React.useState(true);
//     const form = React.useRef();
//     const button = React.useRef();


//     const FETCH_USER_BANKS = async () => {
//         try {
//             const { data, status } = await axios.get(`${BaseUrl}/tutor/bank/detail`, {
//                 headers: {
//                     'Authorization': `Bearer ${user.token}`
//                 }
//             });

//             if (data.success && status === 200) {
//                 setbanks(data.data);
//             }

//             if (status === 401) {
//                 logout();
//             }

//         } catch (error) {
//             if (error.response.status === 401) {
//                 logout();
//             }
//             console.log(error)
//         }

//         setBusy(false);
//     }

//     const handleSubmit = async(e) => {
//         e.preventDefault();

//         try {
//             const { data, status } = await axios.post(`${BaseUrl}/tutor/wallet/cashout`, {
//                 headers: {
//                     'Authorization': `Bearer ${user.token}`
//                 }
//             });
//             console.log(data)

//             if (data?.success && status === 200) {
//                 // setData(data.data);
//             }

//             if (status === 401) {
//                 logout();
//             }

//         } catch (error) {
//             if (error?.response?.status === 401) {
//                 logout();
//             }
//             console.log(error)
//         }
//     }

//     React.useEffect(() => {
//         FETCH_USER_BANKS();
//     }, []);

//     return (
//         <div
//             className="modal fade"
//             id="exampleModal"
//             tabIndex={-1}
//             aria-labelledby="exampleModalLabel"
//             aria-hidden="true"
//         >
//             <div className="modal-dialog">
//                 <div className="modal-content">
//                     <div className="modal-header">
//                         <h1 className="modal-title fs-5" id="exampleModalLabel">
//                             Please select account
//                         </h1>
//                         <button
//                             type="button"
//                             className="btn-close"
//                             data-bs-dismiss="modal"
//                             aria-label="Close"
//                         />
//                     </div>
//                     <div className="modal-body">
//                         <form onSubmit={handleSubmit} ref={form}>
//                             <select className='form-select' disabled={busy && 'disabled'} required>
//                                 {!busy && banks?.length === 0 &&
//                                     <option value="">No Account Found</option>
//                                 }
//                                 {!busy && banks?.length > 0 &&
//                                     <option value="">Select Account</option>
//                                 }
//                                 {!busy && banks.map((bank, index) => (
//                                     <option value={bank?.id} key={index}>{bank?.bank_name} {bank?.account_no}</option>
//                                 ))}
//                             </select>
//                             <div className='text-end mt-3'>
//                                 <button
//                                     type="button"
//                                     className="btn btn-light"
//                                     data-bs-dismiss="modal"
//                                 >
//                                     Cancel
//                                 </button>
//                                 <button type="submit" ref={button} className="btn btn-primary">
//                                     Cashout
//                                 </button>
//                             </div>
//                         </form>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

const Earning = () => {

    const [data, setData] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);

    async function fetch_acc_details() {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/tutor/sessions/details`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });
            // console.log(data)

            if (data?.success && status === 200) {
                setData(data.data);
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }
    }

    React.useEffect(() => {
        fetch_acc_details();
    }, []);

    return (
        <section className='Earning py-5'>
            <div className='card container-lg pb-3'>
                <div className='card-header bg-white py-3 py-lg-4 px-4'>
                    <div className=' d-flex align-items-center justify-content-between'>
                        <h6 className='card-title mb-0 fw-medium'>Earning</h6>
                        <div className='fs-6'>
                            Available Amount
                            <div className='text-end fw-medium'>
                                R {data?.amount ?? '0'}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    {data?.length === 0 &&
                        <div className="fs-6 text-center fw-medium pt-3">No Session Found</div>
                    }
                    {data?.jobs_session?.length > 0 &&
                        <div className="table-responsive px-1 pt-4">
                            <table className="table table-hover">
                                <tbody>
                                    <Details data={data?.jobs_session} />
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>
        </section >
    )
}

export default Earning