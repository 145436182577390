import React, { memo } from 'react'
import At_icon from '../asset/at-icon.svg'
import proposal_icon from '../asset/proposal-icon.svg'
// import msg_icon from '../asset/msg-icon.svg'
import { NavLink } from 'react-router-dom'
import './navbar.css'

const Navbar = ({ lock }) => {

    // const navigate = useNavigate();

    return (
        <nav className='bg-nav p-2 pb-0'>
            <div className='container-xl d-flex align-items-center px-xl-0 px-3 ' style={{ fontSize: 'small' }}>

                <h4 className='text-white col py-2'>Dashboard</h4>
                {!lock &&
                    <div className='d-none d-sm-flex justify-content-around col col-lg-9'>
                        <div className='nav-button'>
                            <NavLink
                                to="/Student/RequestLesson"
                                className={({ isActive, isPending }) => isActive ? "is-active btn text-light fw-normal border-0 py-3 px-0" : "btn text-light fw-normal border-0 py-3 px-0"}
                            >
                                <img src={At_icon} alt='icon' height="26px" className='pe-lg-2' />
                                <span className='d-none d-lg-inline'>Request Lesson</span>
                            </NavLink>;
                            <div className='col m-auto border border-white border-2 '></div>
                        </div>

                        <div className='nav-button'>
                            <NavLink
                                to="/Student/Proposals"
                                className={({ isActive, isPending }) => isActive ? "is-active btn text-light fw-normal border-0 py-3 px-0" : "btn text-light fw-normal border-0 py-3 px-0"}
                            >
                                <img src={proposal_icon} alt='icon' height="26px" className='pe-lg-3' />
                                <span className='d-none d-lg-inline'>Proposals</span>
                            </NavLink>;
                            <div className='col m-auto border border-white border-2 '></div>
                        </div>

                        <div className='nav-button'>
                            <NavLink
                                to="/Student/Sessions"
                                className={({ isActive, isPending }) => isActive ? "is-active btn text-light fw-normal border-0 py-3 px-0" : "btn text-light fw-normal border-0 py-3 px-0"}
                            >
                                <img src={proposal_icon} alt='icon' height="26px" className='pe-lg-3' />
                                <span className='d-none d-lg-inline'>Current Session</span>
                            </NavLink>;
                            <div className='col m-auto border border-white border-2 '></div>
                        </div>

{/* 
                        <div className='nav-button'>
                            <NavLink
                                to="/Student/Message"
                                className={({ isActive, isPending }) => isActive ? "is-active btn text-light fw-normal border-0 py-3 px-0" : "btn text-light fw-normal border-0 py-3 px-0"}
                            >
                                <img src={msg_icon} alt='icon' height="26px" className='pe-lg-3' />
                                <span className='d-none d-lg-inline'>Message</span>
                            </NavLink>;
                            <div className='col m-auto border border-white border-2 '></div>
                        </div> */}
                    </div>
                }
            </div>
        </nav>
    )
}

export default memo(Navbar);
