import React from 'react'
import './css/add-bank.css'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../UserProvider';

const AddBank = () => {

    const [inputs, setInputs] = React.useState({ account_no: '', bank_name: '', account_type: '', bank_address: '', account_name: '' });
    const button = React.useRef();
    const { user, logout, BaseUrl } = React.useContext(UserContext);
    const navigate = useNavigate();

    const handleChange = (e) => {
        let { name, value } = e.target;
        setInputs({ ...inputs, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        button.current.disabled = true;

        try {
            const { data, status } = await axios.post(`${BaseUrl}/tutor/bank/add`, inputs, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
                //,
                // onUploadProgress: progressEvent => {
                //     button.current.innerHTML = (Math.round((progressEvent.loaded * 100) / progressEvent.total)) + '%';
                // }
            });

            if (data?.success && status === 200) {
                button.current.innerHTML = 'Added';
                navigate('/Tutor/Banks')
            }

            if (data?.success === false) {
                button.current.innerHTML = 'Try again';
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            button.current.innerHTML = 'Try again';
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }
        button.current.disabled = false;
    }

    return (
        <section className='Add-bank my-5'>
            <div className='container-lg'>
                <div className='card'>
                    <div className='card-header bg-white py-3 py-lg-4 px-4'>
                        <div className='d-flex align-items-center flex-wrap justify-content-between'>
                            <h6 className='card-title mb-0 fw-medium'>Add bank accounts</h6>
                        </div>
                    </div>
                    <div className='card-body p-2'>
                        <form className='d-flex flex-wrap px-sm-2' autoComplete='off' onSubmit={handleSubmit}>
                            <div className='col-12 col-sm-6 p-2 '>
                                <label className={`col-form-label-sm ms-1 ${inputs?.account_no.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='account_no'>Account number</label>
                                <input className='form-control border-bottom border-0 rounded-0' name='account_no' id='account_no' onChange={handleChange} value={inputs.account_no} placeholder='Account number' type="text" required />
                            </div>
                            <div className='col-12 col-sm-6 p-2 '>
                                <label className={`col-form-label-sm ms-1 ${inputs?.bank_name.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='bank_name'>Bank name</label>
                                <input className='form-control border-bottom border-0 rounded-0' name='bank_name' onChange={handleChange} value={inputs.bank_name} placeholder='Bank name' type="text" required id='bank_name' />
                            </div>
                            <div className='col-12 col-sm-6 p-2'>
                                <label className={`col-form-label-sm ms-1 ${inputs?.account_type.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='account_type'>Bank account type</label>
                                <input className='form-control border-bottom border-0 rounded-0' name='account_type' id='account_type' value={inputs?.account_type} onChange={handleChange} placeholder='Bank account type' type="text" required />
                            </div>
                            <div className='col-12 col-sm-6 p-2'>
                                <label className={`col-form-label-sm ms-1 ${inputs?.bank_address.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='bank_address'>Bank address</label>
                                <input className='form-control border-bottom border-0 rounded-0' name='bank_address' id='bank_address' value={inputs?.bank_address} onChange={handleChange} placeholder='Bank address' type="text" required />
                            </div>
                            <div className='col-12 col-sm-6 p-2'>
                                <label className={`col-form-label-sm ms-1 ${inputs?.account_name.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='account_name'>Name of account</label>
                                <input className='form-control border-bottom border-0 rounded-0' name='account_name' id='account_name' value={inputs?.account_name} onChange={handleChange} placeholder='Name of account' type="text" required />
                            </div>
                            <div className='col-12 d-flex align-items-center justify-content-end gap-2 mt-2 p-2'>
                                <Link className='btn btn-primary' to="/Tutor/Banks" type='button' ref={button}>Cancel</Link>
                                <button className='btn btn-primary' type='submit' ref={button}>Add account</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AddBank
