import React from 'react'
import background from './asset/login.png'
// import user from './asset/user.png'
import axios from 'axios'
// import lock from './asset/lock.png'
import './css/login.css'
import { UserContext } from '../UserProvider'
import { Link, useNavigate } from 'react-router-dom'


const REGISTER_USER = async (payload, BaseUrl) => {
    try {
        const { data } = await axios.post(`${BaseUrl}/user/register`, payload);
        return await data

    } catch (error) {
        console.log(error)
        return await error?.response;
    }
}

const Login = () => {

    const navigate = useNavigate();
    const [data, setData] = React.useState({ role: 'tutor', first_name: '', last_name: '', email: '', password: '', remember: false });
    const [error, setError] = React.useState('');
    const [Onbusy, setOnbusy] = React.useState(false);
    const { setUser, BaseUrl } = React.useContext(UserContext);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value })
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError('');

        if (Onbusy === true) {
            return false;
        }

        setOnbusy(true);

        const { role, first_name, last_name, email, password } = data;

        const payload = {
            'first_name': first_name,
            'email': email,
            'last_name': last_name,
            'password': password,
            'role': role
        }

        const res = await REGISTER_USER(payload, BaseUrl);

        if (res?.success) {
            localStorage.setItem('user', JSON.stringify({ 'token': res?.data.token }));
            setUser({ 'token': res?.data.token });
            navigate(role === 'student' ? '/Student' : '/Tutor');
        }

        else if(res?.code === 'INVALID_REQ'){
            setError(res?.message);
        }

        else {
            setError('Something went wrong. Please try again.');
        }

        setOnbusy(false);
    }

    document.getElementsByTagName('body')[0].style.zoom = '100%'

    return (
        <main className='d-flex vh-100 login m-auto' style={{ maxWidth: '2000px' }}>
            <div className='d-none d-lg-block col h-100' style={{ background: `url(${background})`, backgroundSize: 'cover' }}>
                {/* <img src={background} height="100%" width="100%" alt='background' /> */}

                <div className='d-flex h-75 justify-content-center flex-column align-items-center'>
                    <h5 className='fs-5 fw-normal mb-3'>Looks like you are new here!</h5>
                    <h3>Join the largest Tutors</h3><h3>Community in the world</h3>
                </div>

            </div>
            <div className='col-12 col-lg-7 bg-white px-3 px-sm-5 position-relative'>
                <div className='mt-3 me-4 end-0 position-absolute'><Link to={'/Login'} className='btn border-0a'>Login</Link></div>
                <div className='d-flex flex-column justify-content-center h-100 gap-2'>
                    <div className='fs-6 d-flex align-items-center fw-medium mb-1'>
                        Register as
                        <div className='mx-2'>
                            <select onChange={handleChange} name="role" value={data.role} className="form-select fs-6 m-0 fw-medium border form-select-sm" >
                                <option value="tutor">tutor</option>
                                <option value="student">student</option>
                            </select>
                        </div>
                        on
                    </div>
                    <h4>Tutors Community</h4>
                    <form className='d-flex flex-column mt-4 gap-4 col-12 col-lg-10 col-xl-9' onSubmit={handleSubmit}>

                        <div className='d-flex align-items-center gap-4'>
                            <div className='col border-bottom d-flex align-items-center pb-1'>
                                {/* <label htmlFor='first_name'><img src={user} height="16px" width="auto" alt="text" /></label> */}
                                <input className='border-0 form-control' name='first_name' value={data.first_name} onChange={handleChange} placeholder='First name' type="text" id='first_name' required />
                            </div>

                            <div className='col border-bottom d-flex align-items-center pb-1'>
                                {/* <label htmlFor='last_name'><img src={user} height="16px" width="auto" alt="text" /></label> */}
                                <input className='border-0 form-control' name='last_name' value={data.last_name} onChange={handleChange} placeholder='Last name' type="text" id='last_name' required />
                            </div>
                        </div>


                        <div className='border-bottom d-flex align-items-center pb-1'>
                            {/* <label htmlFor='email'><img src={user} height="16px" width="auto" alt="email" /></label> */}
                            <input className='border-0 form-control' name='email' value={data.email} onChange={handleChange} placeholder='Email' type="email" id='email' required />
                        </div>

                        <div className='border-bottom d-flex align-items-center pb-1'>
                            {/* <label htmlFor='password'><img src={lock} height="16px" alt="lock" /></label> */}
                            <input className='border-0 form-control border-0' value={data.password} onChange={handleChange} name='password' type="password" id='password' placeholder='Password' required />
                        </div>

                        <p className={`m-0 p-0 text-danger ${error.length > 0 ? 'd-block' : 'd-none'}`}>{error}</p>

                        <button className='btn btn-primary mt-2 rounded-4' disabled={Onbusy} type='submit' style={{ width: '160px' }}>
                            {Onbusy ? 'Please wait' : 'Register'}
                        </button>
                    </form>
                </div>
            </div>
        </main>
    )
}

export default Login
