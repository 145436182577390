import React from 'react'
import './AboutUs.css'
import NavBar from '../NavBar/NavBar'
import Tutors from '../Tutors/Tutors'
import Footer from '../Footer/Footer'

const AboutUs = () => {
  return (
    <div>
<div className='about-us'>
<NavBar/>
<div className='About-one'>
  About
</div>

</div>
<Tutors/>
<Footer/>

    </div>
  )
}

export default AboutUs