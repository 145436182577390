import axios from 'axios';
import React from 'react'
import { UserContext } from '../UserProvider';
import Pdf_Icons from './asset/icons-pdf.png';
import docx_Icons from './asset/icons-document.png';
import image_Icons from './asset/icons-jpeg.png';
import './css/profile.css'
import Select from 'react-select';

const ProfileCard = () => {

    const { user, setUser, logout, BaseUrl } = React.useContext(UserContext);
    const button = React.useRef();
    const [inputs, setInputs] = React.useState({ first_name: user?.first_name, last_name: user?.last_name, about: user?.about ?? '', phone: user?.phone ?? '' });

    const handleChange = (e) => {
        let { name, value, type } = e.target;

        if (type === 'text') {
            value = value.replace(/[^a-zA-Z]/g, '');
        }

        if (type === 'number') {
            value = value.replace(/\D/g, '');
        }

        setInputs({ ...inputs, [name]: value });
        button.current.innerHTML = 'Update';
    };

    const handleKeyPress = (event) => {
        if (event.key === 'e') {
            event.preventDefault();
        }
    };

    const handleProfileUpdate = async (e) => {

        e.preventDefault();
        const payload = { ...inputs }
        button.current.disabled = true;
        button.current.innerHTML = 'Update';

        try {
            const { data, status } = await axios.post(`${BaseUrl}/user/update/profile`, payload, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
                //,
                // onUploadProgress: progressEvent => {
                //     button.current.innerHTML = (Math.round((progressEvent.loaded * 100) / progressEvent.total)) + '%';
                // }
            });

            if (data?.success && status === 200) {
                setUser({ ...user, first_name: data.user.first_name, last_name: data.user.last_name, about: data.user.about, phone: data.user.phone });
                button.current.innerHTML = 'Updated';
            }

            if (data?.success === false) {
                button.current.innerHTML = 'Try again';
            }

            if (status === 401) {
                logout();
            }

        } catch (error) {
            button.current.innerHTML = 'Try again';
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        button.current.disabled = false;
    }

    return (
        <div className='card'>
            <div className='card-header bg-white py-3 py-lg-4 px-4'>
                <h5 className='card-title fw-medium mb-0'>Profile Setting</h5>
            </div>
            <div className='card-body'>
                <form className='d-flex flex-wrap px-sm-2' autoComplete='off' onSubmit={handleProfileUpdate}>
                    <div className='col-12 col-sm-6 p-2 '>
                        <label className={`col-form-label-sm ms-1 ${inputs?.first_name.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='first_name'>First name</label>
                        <input className='form-control border-bottom border-0 rounded-0' name='first_name' id='first_name' onChange={handleChange} value={inputs.first_name} placeholder='First name' type="text" required />
                    </div>
                    <div className='col-12 col-sm-6 p-2 '>
                        <label className={`col-form-label-sm ms-1 ${inputs?.last_name.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='last_name'>Last name</label>
                        <input className='form-control border-bottom border-0 rounded-0' name='last_name' onChange={handleChange} value={inputs.last_name} placeholder='Last name' type="text" required id='last_name' />
                    </div>
                    <div className='col-12 col-sm-6 p-2 '>
                        <label className={`col-form-label-sm ms-1`} htmlFor='email'>Email</label>
                        <input className='form-control border-bottom border-0 rounded-0' value={user?.email} id='email' placeholder='Email' type="email" disabled />
                    </div>
                    <div className='col-12 col-sm-6 p-2'>
                        <label className={`col-form-label-sm ms-1 ${inputs?.about.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='about'>About</label>
                        <input className='form-control border-bottom border-0 rounded-0' name='about' id='about' value={inputs?.about} onChange={handleChange} placeholder='About me' type="text" />
                    </div>
                    <div className='col-12 col-sm-6 p-2'>
                        <label className={`col-form-label-sm ms-1 ${inputs?.phone.trim().length > 0 ? 'opacity-1' : 'opacity-0'}`} htmlFor='phone'>Cell number</label>
                        <input className='form-control border-bottom border-0 rounded-0' name='phone' id='phone' value={inputs?.phone} onKeyPress={handleKeyPress} onChange={handleChange} pattern="[0-9]*" placeholder='Cell number' type="number" />
                    </div>
                    <div className='col-12 d-flex align-items-center justify-content-end mt-2 p-2'>
                        <button className='btn btn-primary' type='submit' ref={button}>Update profile</button>
                    </div>
                </form>
            </div>
        </div>
    )
}


const UserCard = () => {

    // URL.createObjectURL(photo)

    const profile = React.useRef();
    const profileInput = React.useRef();
    const [photo, SetPhoto] = React.useState('');
    const [error, setError] = React.useState('');
    const { user, setUser, logout, BaseUrl } = React.useContext(UserContext);

    const handleReset = () => {
        profile.current.src = user.profile;
        profileInput.current.value = '';
        SetPhoto('');
        setError('');
    }

    const isImage = (fileType) => {
        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
        return allowedTypes.includes(fileType);
    }

    const handleFile = (e) => {
        const photo = e.target.files[0];

        if (!isImage(photo?.type)) {
            profile.current.src = user.profile;
            profileInput.current.value = '';
            SetPhoto('');
            setError('File must be image');
            return;
        }

        if (error.length > 0) {
            setError('');
        }
        SetPhoto(photo);
        profileInput.current.value = '';
        profile.current.src = URL.createObjectURL(photo);
    };

    const handleImageUpdate = async (e) => {

        const formData = new FormData();
        formData.append("photos", photo);

        const button = e.target.classList.contains('btn') ? e.target : e.target.parentNode;
        button.disabled = true;

        try {
            const { data, status } = await axios.post(`${BaseUrl}/user/update/profile/image`, formData, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`,
                    'Content-Type': 'multipart/form-data'
                }
                //,
                // onUploadProgress: progressEvent => {
                //     button.current.innerHTML = (Math.round((progressEvent.loaded * 100) / progressEvent.total)) + '%';
                // }
            });

            if (data?.success && status === 200) {
                setUser({ ...user, profile: data.profile });
                SetPhoto('');
                profileInput.current.value = '';
            }

            if (status === 401) {
                logout();
            }

            if (data?.success === false) {
                setError('Somethings wents wrong');
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            setError('Somethings wents wrong');
        }

        button.disabled = false;
    }

    return (
        <div className='card'>
            <div className='card-body'>
                <div className='d-flex'>
                    <label htmlFor='profile_file' className='text-center w-100 position-relative'>
                        <img src={user.profile} ref={profile} height="140px" width="140px" alt='profile' className='profile border shadow-none' />
                        <span className="material-icons position-absolute d-inline-block p-1 btn-primary border-0 btn btn-sm rounded-circle fw-lighter" style={{ fontSize: '17px', marginLeft: '-33px', bottom: '15px', background: 'rgb(8 84 151 / 89%)' }}>
                            edit
                        </span>
                    </label>
                    <input type="file" id='profile_file' onChange={handleFile} ref={profileInput} className='opacity-0' style={{ height: '0px', width: '0px' }} />
                </div>
                <p className={`text-center text-danger mb-0 ${error?.length > 0 ? '' : 'd-none'} `}>{error}</p>
                <div className={`${error.length > 0 ? 'mt-1' : 'mt-3'}`}>
                    <h4 className='user-name text-center'>{user?.first_name + ' ' + user?.last_name}</h4>
                </div>
                <div className='text-center fs-6'>{user?.about}</div>
                {photo?.size > 0 &&
                    <div className='d-flex gap-2 justify-content-center align-items-center mt-2'>
                        <button className='btn btn-primary'>
                            <span className="material-icons" onClick={handleReset}>
                                clear
                            </span>
                        </button>
                        <button className='btn btn-primary' onClick={handleImageUpdate}>
                            <span className="material-icons">
                                done
                            </span>
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

const AddSubjects = ({ setSubjects }) => {

    const { user, logout, BaseUrl } = React.useContext(UserContext);
    const [subjects, SetSubjects] = React.useState([]);
    const [selectedSubjects, setSelectedSubjects] = React.useState([]);
    const [document, setDocument] = React.useState([]);
    const [error, setError] = React.useState('');
    const documentBox = React.useRef();



    const fetch_subjects = async () => {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/subjects`, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
            });

            if (data.success && status === 200) {
                SetSubjects(data.data);
                return;
            }

            if (status === 401) {
                logout();
            }

            setError('Something wents wrong');

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            setError('Something wents wrong');
        }
    }

    const handleChange = (selectedOptions) => {
        setSelectedSubjects(selectedOptions);
    };

    const hadleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        // const label = form.querySelector("label[for='document_attach']")
        const button = form.querySelector("button[type='submit']");
        const select_subjects_values = [];

        for (let index = 0; index < selectedSubjects.length; index++) {
            const selected_subject = selectedSubjects[index];
            if (selected_subject.value.length === 0) {
                continue;
            }
            select_subjects_values.push(selected_subject.value)
        }


        if (select_subjects_values.length === 0) {
            setError('Subjects is Required')
            return
        }

        if (!document.size) {
            setError('Documents is Required')
            return
        }

        button.disabled = true;
        setError('');

        const formData = new FormData();
        formData.append("document", document, document.name);
        formData.append("type", 'tutor');

        for (let index = 0; index < select_subjects_values.length; index++) {
            const subject_value = select_subjects_values[index];
            formData.append("subjects[]", subject_value);
        }


        try {

            const { data, status } = await axios.post(`${BaseUrl}/tutor/add/subjects`, formData, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`,
                    'Content-Type': 'multipart/form-data'
                }
                //,
                // onUploadProgress: progressEvent => {
                //     button.current.innerHTML = (Math.round((progressEvent.loaded * 100) / progressEvent.total)) + '%';
                // }
            });

            if (data.success && status === 200) {
                setSubjects(data.subjects);
                setDocument('');
                setSelectedSubjects([]);
            }

            if (data.success === false) {
                setError('Somethings wents wrong');
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
        }

        button.disabled = false;
    }

    React.useEffect(() => {
        fetch_subjects();
    }, []);

    const handleDocuments = (e) => {

        const file = e.target.files[0];
        const img = documentBox.current.getElementsByTagName('img')[0];

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

        if (allowedTypes.includes(file.type) === false) {
            setDocument('');
            setError('Only PDF, Image, and Word files are allowed')
            e.target.value = '';
            return;
        }

        if (['image/jpeg', 'image/png', 'image/gif'].includes(file.type)) {
            img.src = image_Icons;
        }
        else if (['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(file.type)) {
            img.src = docx_Icons;
        }
        else if (['application/pdf'].includes(file.type)) {
            img.src = Pdf_Icons;
        }

        setError('')
        setDocument(file);
        e.target.value = '';
    }

    const getSubjectsOption = () => {
        const options = []

        for (let index = 0; index < subjects.length; index++) {
            const subject = subjects[index];
            options.push({ value: subject.subject_id, label: subject.name });
        }

        return options;
    }

    return (
        <div className='card mt-3'>
            <div className='card-header bg-white py-3 py-lg-4 px-4'>
                <div className='card-title fs-5 mb-0 fw-medium'>Add Subjects</div>
            </div>
            <div className='card-body'>
                <label className='form-label mt-2 px-2'>Select a subject and attach the academic transcript.</label>
                <form className='px-sm-2' onSubmit={hadleSubmit}>
                    <div className='d-flex align-items-center justify-content-between'>
                        <Select
                            isMulti
                            isDisabled={subjects.length === 0}
                            name="subjects"
                            options={subjects.length === 0 ? [] : getSubjectsOption()}
                            value={selectedSubjects}
                            onChange={handleChange}
                            className="form-control p-0 border-0"
                            classNamePrefix="select"
                        />
                        <label className='btn border text-muted d-flex' htmlFor='document_attach'>
                            <span className='material-icons'>attach_file</span>
                        </label>
                        <input type="file" id='document_attach' onChange={handleDocuments} className='opacity-0' style={{ height: '0px', width: '0px' }} />
                    </div>
                    <div className={`documents-box p-3 text-center rounded-3 my-2 ${document?.size > 0 ? '' : 'd-none'} border`} style={{ maxWidth: 'max-content' }} ref={documentBox}>
                        <img height="50px" width="auto" alt='icon' />
                        <p className='mb-0 pt-2'>{document?.name}</p>
                    </div>
                    {error.length > 0 &&
                        <div className='invalid-feedback d-block'>{error}</div>
                    }
                    <div className='text-end mt-4'>
                        <button className='btn btn-primary' type='submit'>Add</button>
                    </div>
                </form>
            </div >
        </div >
    );
}

const SubjectsList = ({ subjects, setSubjects }) => {

    const { user, logout, BaseUrl } = React.useContext(UserContext);

    const handleDelete = async (e, index) => {
        const button = e.currentTarget;
        const subject_id = e.currentTarget.getAttribute('data');

        try {

            const { data, status } = await axios.delete(`${BaseUrl}/user/delete/subjects/${subject_id}`, {
                headers: {
                    'Authorization': `Bearer ${user?.token}`
                }
            });

            if (data.success && status === 200) {
                setSubjects(prev => prev.filter((_, i) => i !== index));
            }

        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
        }

        button.disabled = false;
    }

    return (
        <div className='card'>
            <div className='card-header bg-white'>
                <h6 className='card-title mb-0 py-2'>Subjects</h6>
            </div>
            <div className='card-body py-1'>
                <ul className="list-group list-group-flush">
                    {subjects.map((subject, index) => (
                        <li className="list-group-item" key={index}>
                            <div className='d-flex justify-content-between align-items-center'>
                                <h6 className='mb-0'>{subject?.subject.name}</h6>
                                {subject?.is_approve === 'true' ?
                                    <button className='btn btn-outline-secondary border-0' data={subject?.subject_id} onClick={(e) => handleDelete(e, index)}>
                                        <span className="material-icons">
                                            delete
                                        </span>
                                    </button>
                                    :
                                    <div className='btn border-0'>Pending</div>
                                }
                            </div>
                        </li>
                    ))}

                    {subjects.length === 0 &&
                        <li className='list-group-item'>No subjects were found.</li>
                    }
                </ul>
            </div>
        </div>
    )
}

const Profile = ({ subjects, setSubjects, gate }) => {
    return (
        <section className='profileSetting pt-5 px-3 px-lg-0 user-select-none'>
            <div className='container-lg'>
                {gate.has('subjects') &&
                    <div className="alert alert-danger col-12 col-md-8" role="alert">
                        Please select a subject for tutoring and attach the academic transcript.
                    </div>
                }
                {!(gate.has('subjects')) && gate.has('is_approve') &&
                    <div className="alert alert-success col-12 col-md-8" role="alert">
                        Please wait for administrative approval for tutoring.
                    </div>
                }
                <div className='d-flex mt-4 flex-wrap-reverse gap-3 gap-lg-5'>
                    <div className='col-12 col-md-8'>
                        <ProfileCard />
                        <AddSubjects setSubjects={setSubjects} />
                    </div>
                    <div className='col d-flex flex-column gap-3'>
                        <UserCard />
                        <SubjectsList subjects={subjects} setSubjects={setSubjects} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Profile
