import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import UserProvider from './UserProvider';
import RoutesHandler from './Routes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
  <UserProvider>
    <RoutesHandler />
  </UserProvider>
  //</React.StrictMode> 
);

reportWebVitals();
