import React from 'react'
import './footer.css';
import At_icon from '../asset/at-icon.svg'
import proposal_icon from '../asset/proposal-icon.svg'
import msg_icon from '../asset/msg-icon.svg'
import { NavLink } from 'react-router-dom'
import { UserContext } from '../../UserProvider';


const Footer = ({ lock }) => {
    const { user, AppUrl } = React.useContext(UserContext);
    return (
        <footer className='dashboard bottom-0 w-100 px-2 rounded-4' >
            {!lock &&
                <div className='border d-flex d-sm-none justify-content-around col-12 bg-white  rounded-top-4 pt-2'>

                    <div className='nav-button'>
                        <NavLink
                         to="/Student/RequestLesson"
                            className={({ isActive, isPending }) => isActive ? "is-active btn text-light fw-normal py-2 px-0" : "btn text-light fw-normal border-0 py-2 px-0"}
                        >
                            <img src={At_icon} alt='icon' height="26px" className='pe-lg-3' />
                        </NavLink>
                        <div className='col m-auto border border-secondary border-2 mt-2'></div>
                    </div>

                    <div className='nav-button'>
                        <NavLink
                          to="/Student/Proposals"
                            className={({ isActive, isPending }) => isActive ? "is-active btn text-light fw-normal py-2 px-0" : "btn text-light fw-normal border-0 py-2 px-0"}
                        >
                            <img src={proposal_icon} alt='icon' height="26px" className='pe-lg-3' />
                        </NavLink>
                        <div className='col m-auto border border-secondary border-2 mt-2'></div>
                    </div>

                    <div className='nav-button'>
                        <NavLink
                            to={`${AppUrl}/student/message/?token=${user.token}`} target="_blank" 
                            className={({ isActive, isPending }) => isActive ? "is-active btn text-light fw-normal py-2 px-0" : "btn text-light fw-normal border-0 py-2 px-0"}
                        >
                            <img src={msg_icon} alt='icon' height="26px" className='pe-lg-3' />
                        </NavLink>
                        <div className='col m-auto border border-secondary border-2 mt-2'></div>
                    </div>
                </div>
            }
        </footer>
    )
}

export default Footer
