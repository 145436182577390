import React from 'react'
import './Instiute.css'
import cardOne from '../../Images/cardOne.png'
import cardTwo from '../../Images/cardTwo.png'
import cardThree from '../../Images/cardThree.png'
const Institute = () => {
  return (
    <div>
        <div className='fluid-institute'>
<h1 className='explore text-center pt-5' style={{fontFamily:'Poppins'}}>Explore Our Institutes</h1>
{/* <div className='container '> */}
<div className="row  row-cols-1 row-cols-md-2 row-cols-lg-3 g-5 py-5  card-one" >
        
        <div className="col">
            <div className="card" style={{borderRadius:'32px',position:'relative'}} >
                <img src={cardOne}  alt="..." style={{  height: '500px'}} className='car'/>
               
            <div className='shadowFirtDiv' style={{bottom:0}}>
              <h2 style={{ textShadow: "3px 5px 5px black",bottom:'0',fontFamily:'Poppins' }} className='px-5 pb-4 Senior'>
                National Senior Certificate
             </h2>
             </div>
              
            </div>
        </div>


        {/*  */}
        <div className="col">
            <div className="card" style={{borderRadius:'32px'}}>
                <img src={cardTwo} alt="..." style={{  height: '500px',borderRadius:'32px'}} className='car'/>
                <div className='shadowFirtDiv' style={{bottom:0}}>
              <h2 style={{ textShadow: "3px 5px 5px black",bottom:'0',fontFamily:'Poppins' }} className='px-5 pb-4 Senior'>
                National Senior Certificate
             </h2>
             </div>
              
              
            </div>
        </div>
        {/*  */}
         {/*  */}
         <div className="col">
            <div className="card" style={{borderRadius:'32px'}}>
                <img src={cardThree}  alt="..."  style={{  height: '500px',borderRadius:'32px'}} className='car'/>
                <div className='shadowFirtDiv' style={{bottom:0}}>
              <h2 style={{ textShadow: "3px 5px 5px black",bottom:'0',fontFamily:'Poppins' }} className='px-5 pb-4 Senior'>
                National Senior Certificate
             </h2>
             </div>
              
              
            </div>
        </div>
        {/*  */}
        {/* </div> */}
        </div>
</div>
    </div>
  )
}

export default Institute