import React from "react";
import "./Testimonals.css";
import EllipseOne from "../../Images/EllipseOne.png";
import comma from '../../Images/comma.png'
import EllipseTWO from "../../Images/EllipseTwo.png";
import commaTwo from '../../Images/commaTwo.png'
import EllipseThree from "../../Images/EllipseThree.png";

const Testimonals = () => {
  return (
    <div>
      <div className="fluid-institute" >
        <div className="community pt-3">
          <h1 style={{ fontWeight: "700", color: "rgba(0, 0, 0, 0.71)",fontFamily:'Poppins' }}>
            Testimonals
          </h1>
          <h3
            className="pt-3"
            style={{ fontWeight: "400", color: "rgba(0, 0, 0, 0.71)",fontFamily:'Poppins' }}
          >
            What our Customers say
          </h3>
        </div>
        {/* cards */}
        <div className="row  row-cols-1 row-cols-md-2 row-cols-lg-3 g-5 py-5  card-one">
          <div className="col">
            <div
              className="card"
              style={{
                position: "relative",
                height: "430px",
                
                borderBottomRightRadius: "32px",
                borderBottomLeftRadius: "32px",
                backgroundColor:'rgba(11, 23, 131, 0.07)'
              }}
            >
              <div className="first-cards">
              <img src={EllipseOne} alt=""  style={{marginTop:'30px',width:'150px',height:'150px'}}></img>
              </div>
              <div className=" comma pt-2">
                <img src={comma} alt=""></img>
              </div>
             <h3  style={{textAlign:'center',fontWeight:'500',fontFamily:'Poppins',paddingTop:'70px'}}>Anasta Wil</h3>
             <p style={{color:'rgba(0, 0, 0, 0.5)',textAlign:'center',fontFamily:'Poppins'}} className="px-4 pt-4">
             Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
             </p>
             
          
            </div>
          </div>
          {/*  */}
          <div className="col">
            <div
              className="card"
              style={{
                position: "relative",
                height: "500px",
               
                borderBottomRightRadius: "32px",
                borderBottomLeftRadius: "32px",
                backgroundColor:'rgba(11, 23, 131, 0.07)'
              }}
            >
              {/*  */}
              <div className="second-cards">
              <img src={EllipseTWO} alt=""  style={{marginTop:'70px',width:'150px',height:'150px'}}></img>
              </div>
              <div className=" comma pt-2">
                <img src={commaTwo} alt="" style={{width:'40px',height:"31px"}}></img>
              </div>
             <h3  style={{textAlign:'center',fontWeight:'500',fontFamily:'Poppins',paddingTop:'70px'}}>Hailey Dan</h3>
             <p style={{color:'rgba(0, 0, 0, 0.5)',textAlign:'center',fontFamily:'Poppins'}} className="px-4 pt-4">
             Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
             </p>
             
              {/*  */}
            </div>
          </div>
          {/*  */}
          {/*  */}
          <div className="col">
          <div
              className="card"
              style={{
                position: "relative",
                height: "430px",
                
                borderBottomRightRadius: "32px",
                borderBottomLeftRadius: "32px",
                backgroundColor:'rgba(11, 23, 131, 0.07)'
              }}
            >
              <div className="third-cards">
              <img src={EllipseThree} alt=""  style={{marginTop:'30px',width:'150px',height:'150px'}}></img>
              </div>
              <div className=" comma pt-2">
              <img src={commaTwo} alt="" style={{width:'40px',height:"31px"}}></img>
              </div>
             <h3  style={{textAlign:'center',fontWeight:'500',fontFamily:'Poppins',paddingTop:'70px'}}>Charlie</h3>
             <p style={{color:'rgba(0, 0, 0, 0.5)',textAlign:'center',fontFamily:'Poppins'}} className="px-4 pt-4">
             Lorem ipsum dolor sit amet, consectetur adipiscing elit.Lorem ipsum dolor sit amet, consectetur adipiscing elit.
             </p>
             
          
            </div>
          </div>
          {/*  */}
        </div>

        {/* cards */}
      </div>
    </div>
  );
};

export default Testimonals;
