import React from "react";
import "./Community.css";
import cube from '../../Images/cube.png'
import review from '../../Images/review.png'
import calarity from '../../Images/calarity.png'

const Community = () => {
  return (
    <div>
      <div className="fluid-community">
        <div className="community">
         <div><h1 style={{ color: "rgba(0, 0, 0, 0.50)",fontFamily:'Poppins' ,fontWeight:'700'}}>Why choose us?</h1></div> 
          <div className="pt-2" ><h3 style={{color:'#000',fontFamily:'Poppins',fontWeight:'400'}}>Benefits of being a Tutors Community Member</h3></div>
          <div className="pt-4" >
            <p style={{color:' rgba(0, 0, 0, 0.50)',textAlign:'center',lineHeight:'26px',fontFamily:'Poppins',fontSize:'20px',fontWeight:'400'}}>
            Tutors Community is a platform where tutor’s make life easy for any student enrolled in a
            module where the tutor did exceptionally well.
            </p>
          
           
          </div>
        </div>
        {/*  */}
      
        <div className="row   py-5  card-one mt-3 ">
        
        <div className="col-lg-5 col-md-6 Modules" style={{position:'relative'}}>
          <div className="cube">
 <img src={cube} alt=""/>
<p style={{ fontWeight: "800",fontFamily:'Poppins'}} className="pt-2" >100+ Modules</p>
<p style={{marginTop:'-10%', paddingTop:'0%',fontWeight:'400',fontFamily:'Poppins'}} >
            Lorem ipsum dolor sit amet consectetur adipisicing.
          </p>
          </div>
          <hr className="ac" style={{width:'65%',marginLeft:'150px',Top:'0',marginTop:'-180px',position:'absolute'}}/>
         
       
        </div>
        {/*  */}
        <div className="col-md-6 col-lg-5 Modules" style={{position:'relative'}}>
          <div className="cube">
 <img src={review} alt="" style={{width:'146px',height:'134px'}} className="im"/>
<p style={{ fontWeight: "800",fontFamily:'Poppins'}} className="pt-2" >Review</p>
<p style={{marginTop:'-10%', paddingTop:'0%',fontWeight:'400',fontFamily:'Poppins'}} >
            Lorem ipsum dolor sit amet consectetur adipisicing.
          </p>
          </div>
          <hr className="ab"  style={{width:'65%',marginLeft:'150px',Top:'0',marginTop:'-180px',position:'absolute'}}/>
         
       
        </div>
        {/*  */}
        {/*  */}
        <div className="col-lg-2 col-md-12 Modules" style={{position:'relative'}}>
          <div className="cube">
 <img src={calarity} alt=""  style={{width:'146px',height:'134px'}}/>
<p style={{ fontWeight: "800",fontFamily:'Poppins'}} className="pt-2" >100+ Modules</p>
<p style={{marginTop:'-10%', paddingTop:'0%',fontFamily:'Poppins'}} >
            Lorem ipsum dolor sit amet consectetur adipisicing.
          </p>
          </div>

         
       
        </div>
        {/*  */}
      
        </div>
       
      </div>
    </div>
  );
};

export default Community;
