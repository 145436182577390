import axios from 'axios';
import React from 'react'
import { UserContext } from '../UserProvider';
import './css/currentSession.css'
import { Link } from 'react-router-dom';

const List = ({ currentSession, setCurrentSession }) => {

    const { user, logout, BaseUrl } = React.useContext(UserContext);

    function printStringWithLength(str, length) {
        if (str.length >= length) {
            return str.slice(0, length) + ' ...';
        }
        return str.padEnd(length, ' ');
    }

    function getTimeAndDate(timestamp) {
        const dateObj = new Date(timestamp);
        // const hours = String(dateObj.getHours()).padStart(2, '0');
        // const minutes = String(dateObj.getMinutes()).padStart(2, '0');
        const monthIndex = String(dateObj.getMonth());
        const day = String(dateObj.getDay() + 1).padStart(2, '0');
        const year = dateObj.getFullYear();

        const months = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];

        const month = months[monthIndex];

        // const time = `${hours}:${minutes}`;
        const date = `${day}-${month}-${year}`;

        return `${date}`;
    }

    const complete = async (token, index) => {

        try {
            const { data, status } = await axios.post(`${BaseUrl}/student/job/complete`, { sessionToken: token }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setCurrentSession(prevItems => {
                    const newItems = [...prevItems]; // Create a new copy of the array
                    const oldValue = { ...newItems[index] }; // Create a new copy of the object

                    oldValue.job = { ...oldValue.job, status: 'completed' }; // Update the status property

                    newItems[index] = oldValue; // Update the value at the specified index
                    return newItems; // Set the new array as the state value
                });
            }

            if (status === 401) {
                logout();
            }


        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }
    }



    return (
        currentSession.map((session, index) => (
            <div className='card-list d-flex flex-wrap justify-content-between border-bottom py-4' key={index}>
                <div className='user-info-box d-flex gap-3'>
                    <img src={session?.user_tutor?.profile} height="50px" width="50px" className='rounded-circle' alt='profile' />
                    <div className='user-info'>
                        <h6 className='fw-bold'>{session?.user_tutor?.first_name + ' ' + session?.user_tutor?.last_name}</h6>
                        <h6>{getTimeAndDate(session?.created_at)}</h6>
                    </div>
                </div>

                <div className='msg-info'>
                    <h6 className='fw-bold'>Session</h6>
                    <h6>{printStringWithLength(session?.token, 20)}</h6>
                </div>

                <div className='subject-info'>
                    <h6 className='fw-bold'>{printStringWithLength(session?.job?.subject.name, 20)}</h6>
                    <h6>{printStringWithLength(session?.job?.title, 20)}</h6>
                </div>

                <div className='minute-info'>
                    <h6 className='fw-bold'>Price</h6>
                    <h6>R {session?.job?.price}</h6>
                </div>

                <div className='action-btn d-flex align-items-center justify-content-end gap-2'>

                    {session.job?.status === "progress" &&
                        <>
                            <Link to={`http://localhost:8000/student/message/${session.token}?token=${user.token}`} target="_blank" className='btn btn-primary' type='button'>
                                <span className='material-icons'>chat</span>
                            </Link>
                            <Link to={`http://localhost:8000/student/meet/${session.token}?token=${user.token}`} target="_blank" className='btn btn-primary' type='button'>
                                <span className='material-icons'>co_present</span>
                            </Link>
                            <button className='btn btn-primary' onClick={() => complete(session.token, index)}>
                                <span className='material-icons'>done</span>
                            </button>
                        </>
                    }

                    {session.job?.status === "completed" &&
                        <button className='btn btn-primary' disabled>
                            Completed
                        </button>
                    }
                </div>

            </div>
        ))
    )

}

const CurrentSession = () => {


    const [busy, setBusy] = React.useState(true);
    const [currentSession, setCurrentSession] = React.useState([]);
    const { user, logout, BaseUrl } = React.useContext(UserContext);

    React.useEffect(() => {
        FETCH_PROPOSALS()
    }, []);


    const FETCH_PROPOSALS = async () => {
        try {
            const { data, status } = await axios.get(`${BaseUrl}/student/job/sessions/fetch/all`, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            });

            if (data?.success && status === 200) {
                setCurrentSession(data.data);
                // console.log(data.data)
            }

            if (status === 401) {
                logout();
            }


        } catch (error) {
            if (error?.response?.status === 401) {
                logout();
            }
            console.log(error)
        }

        setBusy(false);
    }


    return (
        <section className='sessions-student card-list-box d-flex justify-content-center align-items-center py-5 px-3 px-lg-0'>
            <div className='card container-lg pb-3'>
                <div className='card-header bg-white py-3 py-lg-4 px-4'>
                    <h6 className='card-title mb-0 fw-medium'>Current Sessions</h6>

                </div>
                <div className='card-body py-0'>
                    {busy ?
                        <div className='p-5 text-center'>
                            <div className="spinner-border" role="status" style={{ color: '#074276' }}>
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        :
                        <>
                            {currentSession.length === 0 ?
                                <p className='fs-5 my-2 p-3 text-center'>No Sessions Found</p>
                                :
                                <List currentSession={currentSession} setCurrentSession={setCurrentSession} />
                            }
                        </>
                    }
                </div>
            </div>
        </section>
    )
}

export default CurrentSession
