import React from 'react'
import './About.css'
import one from '../../Images/one.png';
import two from '../../Images/two.png';
import three from '../../Images/three.png';
import four from '../../Images/four.png';
const About = () => {
  return (
    <div>
      <div className='fluid'>
        <div className='row row-one' style={{ paddingTop: '60px' }}>
          <div className='col col-one' >

            <h1 className='about' style={{ fontFamily: 'Poppins' }}>About us</h1>
            <p className='paras' style={{ fontFamily: 'Poppins' }}>
              Tutors Community is a platform where tutor’s make life easy for any student enrolled in a module where the tutor
              did exceptionally well .The idea is to elevate students confidence and marks in any module he/she enrolled to.
              Tutors Community was established in 2021 with an idea of make life easy for any student which might be struggling to
              understand anything related to his/her studies.
              Tutors Community welcomes you in a platform and in the community of teachers.
            </p>
            <button className="learnMoreBtn" style={{ fontFamily: 'Poppins' }}>Learn more</button>

          </div>
          <div className='col '>
            <div className='img-one '>
              <img src={one} alt='' className='one-img'></img>
              <img src={two} alt='' className='two-img'></img>
            </div>
            <div className='img-two pt-4'>
              <img src={three} alt='' className='three-img'></img>
              <img src={four} alt='' className='four-img'></img>
            </div>


          </div>
        </div>










      </div>




    </div>
  )
}

export default About