import React from 'react';
import Loading from './loading';
import Auth from './Auth';

// Create a context for user data
export const UserContext = React.createContext({});
const BaseUrl = 'https://test.tutorscom.com/api';
const AppUrl = 'https://test.tutorscom.com';

export default function UserProvider({ children }) {

    const hasToken = localStorage.hasOwnProperty('user') ? true : false

    const [user, setUser] = React.useState(
        hasToken ? JSON.parse(localStorage.user) : {}
    );

    const Authorizing = async () => {
        if (!(await Auth.session({ token: user.token, 'BaseUrl': BaseUrl }))) {
            localStorage.removeItem('user');
            setUser({});
            return false
        }
        setUser({ token: user.token, email: Auth.user.email, first_name: Auth.user.first_name, last_name: Auth.user.last_name, profile: Auth.user.profile, about: Auth.user.about, phone: Auth.user.phone })
        return true;
    }

    React.useEffect(() => {
        if (('token' in user) && !('email' in user)) { Authorizing() };
    }, [user]);

    if (('token' in user) && !('email' in user)) {
        return <Loading display="Authorizing" />
    }

    function logout() {
        setUser({});
        localStorage.removeItem('user');
    }

    return (
        <UserContext.Provider value={{ user, setUser, logout, BaseUrl, AppUrl }}>
            {children}
        </UserContext.Provider>
    );
}